import './not-found.styles.scss';

const NotFound = () => {

  console.log(process.env.REACT_APP_API_URL);
  return(
    <div className="not-found-container">
      <p>404. There is nothing here.</p>
    </div>
  )
};

export default NotFound;