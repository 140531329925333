import { Routes, Route } from 'react-router-dom';
import ResetPassword from './components/reset-password/reset-password.component';
import NotFound from './components/not-found/not-found.component';

function App() {

  return (
    <Routes>
      <Route path="reset-password/eaglequote" element={<ResetPassword />}></Route>
      <Route path='*' element={<NotFound />}/>
    </Routes>
  );


}

export default App;
