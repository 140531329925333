export const BASE_API_URL = process.env.REACT_APP_API_URL;
export const ENV = process.env.REACT_APP_ENV;

export async function verifyToken(userId, token) {
  let url;
  if (ENV === "production") {
    url = `${BASE_API_URL}/mobile/user/Verify-Reset-Password-Token`;
  } else {
    url = `${BASE_API_URL}/auth/verify-reset-password-token`;
  }

  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ userId, token }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return result;
}

export async function resetPassword(userId, token, newPassword) {
  let url;
  if (ENV === "production") {
    url = `${BASE_API_URL}/mobile/user/Reset-Password`;
  } else {
    url = `${BASE_API_URL}/auth/reset-password`;
  }

  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ userId, token, newPassword }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return result;
}

export async function forgotPassword(email) {
  let url;
  let payload;
  if (ENV === "production") {
    url = `${BASE_API_URL}/mobile/user/Forgot-Password`;
    payload = { Email: email.trim(), device: "web" };
  } else {
    url = `${BASE_API_URL}/auth//forgot-password`;
    payload = {
      email: email.trim(),
      device: "web",
      mainUrl: "eagle-quote-staging.blackfin.tools",
    };
  }

  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return result;
}
